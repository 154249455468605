<template>
  <div></div>
</template>
<style>
.btn-login {
  font-size: 2em !important;
  width: 100% !important;
  height: 60px !important;
  color: #ffffff !important;
  background: #ab2025 !important;
}

@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@500&display=swap");

.fontPrompt {
  font-family: "Prompt", sans-serif;
  font-size: 20px;
}
</style>
<script>
import axios from "axios";
import { header_token } from "@/website/token";
import { shopService_dotnet, branchService_dotnet } from "@/website/global";
import { remainDateCheck } from "@/website/global_function";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
export default {
  data: () => ({
    errorMessage: "",
    warehouse_theme: null,
    valid: true,
    loading: false,
    username: "",
    password: "",
    show_password: false,
    logo: null,
    // size จอ
    size: "lg",
    window: {
      width: 0,
      height: 0,
    },
    // ขนาดจอ
    Window: "pc",
  }),
  async created() {
    var path = localStorage.getItem("routepath");

    window.location = path;
  },
  watch: {
    size() {
      this.Window = this.size == "xs" || this.size == "sm" ? "mobile" : "pc";
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async GoToLogin() {
      this.loading = true;
      let response = await axios.post(
        shopService_dotnet + "StaffAuthentication/staff-branch-login",
        {
          username: this.username == "" ? null : this.username,
          password: this.password == "" ? null : this.password,
          token: this.packhaiToken,
        },
        { headers: header_token }
      );
      this.loading = false;
      if (response.status == 200 && response.data.staffBranch != null) {
        var staffBranch = response.data.staffBranch;
        var staffBranchPermissionItem = response.data.staffBranchPermissionItem;
        var branchPermission = response.data.branchPermission;
        var toDayFirstLogin = response.data.toDayFirstLogin;
        var endSubscriptionDate = response.data.endSubscriptionDate;

        localStorage.setItem("authorization_token", response.data.token);
        var next = true;
        if (endSubscriptionDate != null) {
          var remaindate = 0;
          remaindate = this.remainDateCheck(endSubscriptionDate);
          if (remaindate <= 0) {
            localStorage.setItem("Branch_BranchID", staffBranch.branchId);
            next = false;
            this.$router.push("/gotopackage");
          }
        }

        if (next) {
          if (staffBranch != null) {
            this.AlertSuccess();
            var permission = {};
            for (var i in branchPermission) {
              if (staffBranch.isMasterUser) {
                permission[branchPermission[i].nameEh] = true;
              } else {
                var isActive = staffBranchPermissionItem.filter(
                  (x) => x.staffBranchPermissionID == branchPermission[i].id
                );
                if (isActive.length > 0) {
                  permission[branchPermission[i].nameEh] = true;
                } else {
                  permission[branchPermission[i].nameEh] = false;
                }
              }
            }

            localStorage.setItem("permission", JSON.stringify(permission));
            localStorage.setItem("Branch_BranchID", staffBranch.branchId);
            localStorage.setItem("Branch_StaffID", staffBranch.id);
            localStorage.setItem("Branch_StaffName", staffBranch.name);
            localStorage.setItem("Branch_ToDayFirstLogin", toDayFirstLogin ? 1 : 0);
            if (localStorage.getItem("location_href_tmp") == null) {
              this.$router.push("/dashboard-statistics");
            } else {
              window.location = localStorage.getItem("location_href_tmp");
              localStorage.removeItem("location_href_tmp");
            }
          } else {
            this.loading = false;
            this.AlertWarning("Username Password ไม่ถูกต้อง");
          }
        }
      } else if (response.data.is_not_found) {
        this.errorMessage = "ไม่พบผู้ใช้นี้";
      } else if (response.data.is_wrong_password) {
        this.errorMessage = response.data.message;
      } else if (response.data.is_lock == true) {
        this.$swal({
          type: "error",
          text: response.data.message,
        });
      } else {
        this.AlertError();
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 600) {
        this.size = "xs";
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = "sm";
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = "md";
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = "lg";
      } else if (this.window.width >= 1904) {
        this.size = "xl";
      }
    },
    remainDateCheck,
    AlertSuccess,
    AlertWarning,
    AlertError,
  },
};
</script>
